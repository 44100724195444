import styled from "styled-components";
import {useState} from "react";

export default function FaqBlock({array}) {
	const [active, setActive] = useState(0)
	const renderArray = (array) => {
		return (
			array.map((item, index) => {
				return <p key={index}>{item}</p>
			})
		)
	}
	return (
		<Wrapper className="faq_block">
			{
				array.map((item, index) => {
					return (
						<div
							onClick={() => setActive(index)}
							className={`item ${active === index ? "active" : ""}`}
							key={index}
						>
							<h4>{item.title}</h4>
							<div className="list">
								{renderArray(item.description)}
							</div>
						</div>
					)
				})
			}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	.item{
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 2px solid #3D3D55;
		position: relative;
		padding-right: 60px;
		cursor: pointer;
		&:after{
			content: url("/pic/plus.svg");
			position: absolute;
			top: 5px;
			right: 0;
		}
		&.active{
			&:after{
				content: url("/pic/minus.svg");
				top: 3px;
			}
			.list{
				display: block;
			}
		}
		&:last-child{
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
	}
	h4{
		font-size: 24px;
		line-height: 140%;
		@media only screen and (max-width: 600px) {
			font-size: 18px;
			line-height: 140%;
		}
	}
	.list{
		margin-top: 10px;
		font-size: 18px;
		line-height: 140%;
		font-weight: 500;
		color: #9F9EA5;
		display: none;
		p{
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
`
