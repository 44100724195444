import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Title from "components/Title";
import {useState} from "react";
import numberFormat from "helpers/numberFormat";
import Link from "next/link";
import Image from "next/image";
import LiveAnimation from "components/LiveAnimation";
import Responsive from "helpers/Responsive";

export default function MainMint({t}) {
	const [minted, setMinted] = useState(5000)
	return (
		<Wrapper>
			<CenterBlock>
				<Title><h2>{t.title} <LiveAnimation/></h2></Title>
				<p className="description">{t.description}</p>
				<div className="list_block">
					<Responsive width={820}>
						<div className="mint_bottle">
							<Image src="/pic/mint-bottle.png" width={269} height={413} priority={true} alt="Mint is live"/>
						</div>
					</Responsive>
					<div className="list">
						<div className="item">
							<div className="value">{numberFormat(1000)} <span>TRX</span></div>
							<p className="item_description">{t.fair_price}</p>
						</div>
						<div className="item">
							<div className="value">{numberFormat(minted)}<span>/{numberFormat(15000)}</span></div>
							<p className="item_description">{t.mutants_left}</p>
						</div>
						<div className="item">
							<div className="value">100%</div>
							<p className="item_description">{t.fait_random_mint}</p>
						</div>
					</div>
				</div>
				<div className="link">
					<Link href="/mint">{t.mint_now_button}</Link>
				</div>
			</CenterBlock>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	.center_block{
		padding-top: 120px;
	}
	.description{
		text-align: center;
		margin-top: 8px;
		font-size: 24px;
		line-height: 140%;
		@media only screen and (max-width: 600px) {
			font-size: 18px;
			line-height: 140%;
		}
	}
	.mint_bottle{
		position: absolute;
		bottom: 10px;
		left: -50px;
	}
	.list_block{
		position: relative;
	}
	.list{
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
		@media only screen and (max-width: 1024px) {
			flex-direction: column;
		}
		.item{
			width: calc(100% / 3 - 20px);
			background: rgba(19, 19, 35, 0.6);
			backdrop-filter: blur(30px);
			border-radius: 36px;
			padding: 30px;
			text-align: center;
			@media only screen and (max-width: 1024px) {
				width: 100%;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		.value{
			font-size: 40px;
			line-height: 140%;
			font-weight: bold;
			font-family: "Halvar Breitschrift", sans-serif;
			@media only screen and (max-width: 600px) {
				font-size: 30px;
				line-height: 140%;
			}
			span{
				color: #3D3D57;
			}
		}
		.item_description{
			font-size: 24px;
			line-height: 140%;
			margin-top: 8px;
			@media only screen and (max-width: 600px) {
				font-size: 18px;
				line-height: 140%;
			}
		}
	}
	.link{
		margin-top: 40px;
		display: flex;
		justify-content: center;
		a{
			background: #E2EE44;
			border-radius: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 24px;
			line-height: 140%;
			color: #000;
			height: 80px;
			padding: 20px 120px;
			@media only screen and (max-width: 600px) {
				padding: 20px;
				width: 100%;
				border-radius: 16px;
				height: 60px;
				font-size: 18px;
				line-height: 140%;
			}
		}
	}
	.title{
		display: flex;
		text-align: center;
		justify-content: center;
		h2{
			display: flex;
			align-items: center;
		}
	}
	.live_animation{
		margin-left: 20px;
	}
`
